import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import MenuIcon from '@mui/icons-material/Menu'
import { useTheme } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import React, { Fragment, useState } from 'react'

import ComparaCarIcon from '../icons/brands/ComparaCar'

const drawerWidth = 327

const links = [
  {
    href: '/assinar',
    title: 'Carros por assinatura'
  },
  {
    href: '/avalieseucarro',
    title: 'Avalie seu carro'
  },
  {
    href: '/conteudo',
    title: 'Conteúdo'
  },
  {
    href: '/sobre',
    title: 'Sobre'
  }
]

const drawerLinks = [
  ...links,
  {
    href: '/termos_de_uso',
    title: 'Termos de uso'
  }
]

interface iHeader {}

const Header: React.FC<iHeader> = () => {
  const router = useRouter()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = useState(false)

  const background = theme.palette.header.background
  const color = theme.palette.header.color

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <Box sx={{ mt: 3 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 3
        }}
      >
        <ComparaCarIcon height="25.45" width="145" />
        <IconButton onClick={handleDrawerToggle} sx={{ color: color }}>
          <ChevronLeftIcon />
        </IconButton>
      </Box>
      <Divider />
      <List sx={{ paddingX: 3, pt: 6 }}>
        {drawerLinks.map((link, i) => (
          <Fragment key={i}>
            <ListItem
              disablePadding
              sx={{
                '&:not(:first-child)': {
                  pt: 3
                }
              }}
            >
              <ListItemButton
                onClick={() => {
                  handleDrawerToggle()
                  router.push(link.href)
                }}
                sx={{ paddingX: 0, color: color }}
              >
                <ListItemText primary={link.title} />
                <ListItemIcon sx={{ justifyContent: 'end', color: color }}>
                  <ChevronRightIcon />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
    </Box>
  )

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: background,
          boxShadow: 'none'
        }}
      >
        <Container maxWidth="xl">
          <Toolbar sx={{ height: '80px' }}>
            <NextLink href="/" passHref>
              <ComparaCarIcon />
            </NextLink>
            <Box sx={{ flexGrow: 1 }} />
            {links.map((link, i) => (
              <NextLink key={i} href={link.href} passHref legacyBehavior>
                <Link color={color} variant="body1" sx={{ ml: 4, display: { xs: 'none', md: 'block' } }}>
                  {link.title}
                </Link>
              </NextLink>
            ))}

            <IconButton
              color="primary"
              aria-label="open menu"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { md: 'none' }, color: color }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        anchor="left"
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={{
          display: { sm: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: background }
        }}
      >
        {drawer}
      </Drawer>
    </>
  )
}

export default Header
