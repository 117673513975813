import GoogleIcon from '@mui/icons-material/Google'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Rating from '@mui/material/Rating'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

interface GoogleReviewProps {
  rating?: number
  totalReviews?: number
}

export default function GoogleReviewBadge({ rating = 4.9, totalReviews = 92 }: GoogleReviewProps) {
  return (
    <Card
      sx={{
        maxWidth: 200,
        p: 1.5,
        backgroundColor: '#f8f9fa',
        boxShadow: 1,
        '&:hover': { boxShadow: 2 }
      }}
    >
      <Stack spacing={0.5}>
        <Box display="flex" alignItems="center" gap={0.5}>
          <GoogleIcon sx={{ fontSize: 20 }} />
          <Typography variant="body2" fontWeight="medium">
            Google
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="h6" fontWeight="bold">
            {rating}
          </Typography>
          <Rating value={rating} precision={0.1} readOnly size="small" />
        </Box>

        <Typography variant="caption" color="text.secondary">
          {totalReviews} avaliações
        </Typography>
      </Stack>
    </Card>
  )
}
